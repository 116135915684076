<template>
  <div class="user-manage">
    <el-table
      table-layout="auto"
      v-loading="loading"
      :row-class-name="className"
      :data="users"
      style="width: 100%; height: 85vh; margin-top: 20px"
      @sort-change="handleSortChange"
      :default-sort="{ prop: 'uid', order: 'ascending' }"
    >
      <el-table-column label="ID" prop="uid" sortable="custom" />
      <el-table-column label="Username" prop="uname" sortable="custom" />
      <el-table-column label="Email" prop="email" />
      <el-table-column label="Points" prop="points" sortable="custom" />
      <el-table-column label="Level" prop="admin" sortable="custom">
        <template #default="scope">
          <el-tag :type="level[scope.row.admin].tag">
            Lv.{{ scope.row.admin }} {{ level[scope.row.admin].name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Comment Authority" prop="commentAuthority" sortable="custom">
        <template #default="scope">
          <a-tag :color="scope.row.commentAuthority == 1 ? 'purple':'gray'">
            {{ scope.row.commentAuthority == 1 ? 'Comment Freely':'Need Moderating' }}
          </a-tag>
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input
            v-model="search"
            size="small"
            placeholder="Type name to search"
            prefix-icon="Search"
          />
        </template>
        <template #default="scope">
          <template v-if="currentUser.admin == 3 || currentUser.admin > scope.row.admin">
            <el-button size="small" @click="handleAuth(scope.row)">Auth</el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.row)">delete</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="visible" title="Auth" width="600">
      <el-radio-group v-model="radio">
        <el-radio v-for="(l, k) in level" :key="k" :label="k">
          <el-tag :type="l.tag"> Lv.{{ k }} {{ l.name }} </el-tag>
        </el-radio>
      </el-radio-group>
      <br />
      <br />
      Allow this user to submit comments without moderating:
      <br />
      <el-switch
        v-model="value1"
        size="small"
        class="mb-2"
        active-text="Approve"
        inactive-text="Disapprove"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">Cancel</el-button>
          <el-button type="primary" @click="confirm"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
    <div
      style="width: 58%; margin: 0 auto; margin-top: 30px; margin-bottom: 10px"
    >
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :currentPage="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :pager-count="10"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { get, post } from "@/api";
import { watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  name: "UserManage",
  setup() {
    const route = useRoute();
    const radio = ref(0);
    const visible = ref(false);
    const total = ref(0);
    const currentPage = ref(1);
    const pageSize = ref(17);
    const loading = ref(true);
    const users = ref([]);
    const user = ref();
    const search = ref("");
    const sortField = ref("uid"); // Default sortField
    const sortOrder = ref("asc"); // Default sortOrder
    const currentUser = ref({ admin: 0 }); // Mock current user for example, replace with actual user data
    const level = [
      { name: "Regular", tag: "success" },
      { name: "Moderator", tag: "" },
      { name: "Admin", tag: "warning" },
      { name: "Super", tag: "danger" },
    ];
    const value1 = ref(true);

    watch(
      () => search.value,
      () => {
        load();
      }
    );

    onMounted(() => {
      // Check if user is logged in and fetch user data
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then(res => {
          if (res.res == 1) {
            currentUser.value = res.user;
          } else {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem('Authorization');
          }
        });
      }
      load();
    });

    const confirm = () => {
      post("/admin/auth", { auth: radio.value, uid: user.value.uid,moderator: currentUser.value.uid }).then(
        (res) => {
          if (res.res === 1) {
            var comment_authority = 0;
            if (value1.value == true) {
              comment_authority = 1;
            } else {
              comment_authority = 0;
            }
            post("/admin/moderating-user-comment-authority", {
              moderator:currentUser.value.uid,
              userId: user.value.uid,
              comment_authority: Number(comment_authority),
            });
            ElMessage({
              type: "success",
              message: "Auth Changed",
            });
            visible.value = false;
            load();
          }
        }
      );
    };

    const handleAuth = (row) => {
      if (row.admin == 3) {
        ElMessage({
          type: "error",
          message: "You have no authority to manipulate.",
        });
      } else {
        console.log(row.admin);
        radio.value = row.admin;
        user.value = row;
        visible.value = true;
      }
    };

    const handleDelete = (row) => {
      ElMessageBox.confirm('Are you sure you want to delete this user?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        if (currentUser.value.admin > row.admin) {
          user.value = row;
          post("/admin/delete-user", { auth: radio.value, uid: user.value.uid, moderator:currentUser.value.uid })
            .then((res) => {
              if (res.res === 1) {
                ElMessage({
                  type: "success",
                  message: "User Deleted",
                });
                load(); 
              } else {
                ElMessage({
                  type: "error",
                  message: "Error Deleting User",
                });
              }
            })
            .catch((error) => {
              console.error('Error deleting user:', error);
              ElMessage({
                type: "error",
                message: "Error Deleting User",
              });
            });
        } else {
          ElMessage({
            type: "error",
            message: "You have no authority to delete.",
          });
        }
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    };

    function handleSortChange({ prop, order }) {
      sortField.value = prop;
      sortOrder.value = order === "ascending" ? "asc" : "desc";
      load();
    }

    function className(row) {
      switch (Number(row.row.admin)) {
        case 0:
          return "success-row";
        case 1:
          return "primary-row";
        case 2:
          return "warning-row";
        case 3:
          return "danger-row";
        default:
          return "";
      }
    }

    function handleCurrentChange(val) {
      currentPage.value = val;
      load();
    }

    function load() {
      loading.value = true;
      post("/user/user-info", {
        pageNum: currentPage.value,
        pageSize: pageSize.value,
        search: search.value,
        sort: sortField.value, // Pass sortField to backend
        isAsc: sortOrder.value, // Pass sortOrder to backend
      }).then((res) => {
        loading.value = false;
        users.value = res.users.filter(user => user.status === 1);
        total.value = res.total;
      });
    }

    return {
      users,
      className,
      level,
      loading,
      handleCurrentChange,
      currentPage,
      pageSize,
      total,
      search,
      handleAuth,
      handleDelete,
      visible,
      user,
      radio,
      confirm,
      value1,
      handleSortChange,
      currentUser
    };
  }
};
</script>



<style scoped>
.user-manage {
  width: 1260px;
  margin: 0 auto;
  /* border: 1px solid; */
}

.el-table .success-row {
  background: var(--el-color-success-light-9);
}
.el-table .primary-row {
  --el-table-tr-bg-color: var(--el-color-primary-light-9);
}
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .danger-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}
</style>
